<template>
  <div>
    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method) || !!formData.qmrApprovedAt"
        class="col-12 col-sm-4"
        label="รหัส"
        v-model="formData.code">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method) || !!formData.qmrApprovedAt"
        class="col-12 col-sm-8"
        label="ชื่อ"
        v-model="formData.name"
        :validations="[
          {text: 'required!', value: v.formData.name.$dirty && v.formData.name.$error}
        ]">
      </sgv-input-text>

      <DetailFormCparInput
        label="เลขที่ใบ CPAR เดิม (ถ้ามี)"
        placeholder=""
        :disabled="$auth.disabled(method) || !!formData.qmrApprovedAt"
        class="col-6"
        select="id"
        :paperId="paperId"
        :paperType="paperType"
        :templateType="templateType"
        v-model="formData.cparId">
      </DetailFormCparInput>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6"
        label="หมายเหตุ"
        v-model="formData.remark">
      </sgv-input-text>
    </div>

    <div class="hr-divider mb-3 my-2">
      <ul class="nav nav-pills hr-divider-content hr-divider-nav">
        <li class="nav-item">
          <a class="nav-link active" href="#" @click.prevent="">
            ผู้ออกเอกสาร CPAR บันทึก
          </a>
        </li>
      </ul>
    </div>

    <div class="form-row">
      <DetailFormUser
        class="col-12"
        title="ผู้แจ้ง"
        :value="getUser('reviewed')"
        :isSubmit="!formData.reviewedAt && $auth.hasRole(`paper:${paperType}:review`)"
        :isUndo="!formData.auditorApprovedAt && formData.reviewedAt && $auth.hasRole(`paper:${paperType}:review`)"
        :submitFunc="reviewSubmit"
        :undoFunc="reviewUndo"
        @updated="$emit('updated', null)">
      </DetailFormUser>
    </div>

    <div class="form-row">
      <sgv-input-radio
        :noClick="$auth.disabled(method) || !!formData.qmrApprovedAt"
        label="เอกสาร CPAR ออกเพื่อพิจารณา"
        class="col-12"
        :options="cparTypes"
        select="value"
        inline
        v-model="formData.cparType"
        :validations="[
          {text: 'required!', value: !v.formData.cparType.required && v.formData.$dirty}
        ]">
        <template slot-scope="option">
          <span :class="option.class">{{option.text}}</span>
        </template>
      </sgv-input-radio>

      <sgv-input-radio
        :noClick="$auth.disabled(method) || !!formData.qmrApprovedAt"
        label="หัวข้อ"
        class="col-12"
        :options="cparNames"
        select="value"
        inline
        v-model="formData.cparName"
        :validations="[
          {text: 'required!', value: !v.formData.cparName.required && v.formData.$dirty}
        ]">
        <template slot-scope="option">
          {{option.text}}
        </template>
      </sgv-input-radio>

      <sgv-input-number
        v-if="['IA', 'EA'].includes(formData.cparName)"
        :disabled="$auth.disabled(method) || !!formData.qmrApprovedAt"
        class="col-6 col-sm-4"
        label="ครั้งที่"
        v-model="formData.cparCount">
      </sgv-input-number>

      <sgv-input-text
        v-if="['NC', 'OT'].includes(formData.cparName)"
        :disabled="$auth.disabled(method) || !!formData.qmrApprovedAt"
        class="col-6 col-sm-4"
        label="ระบุ"
        v-model="formData.cparRemark">
      </sgv-input-text>
    </div>

    <div class="hr-divider mb-3 my-2">
      <ul class="nav nav-pills hr-divider-content hr-divider-nav">
        <li class="nav-item">
          <a class="nav-link active" href="#" @click.prevent="">
            รายละเอียดข้อบกพร่อง
          </a>
        </li>
      </ul>
    </div>

    <div class="form-row">
      <sgv-input-number
        :disabled="$auth.disabled(method) || !!formData.qmrApprovedAt"
        class="col-6 col-sm-3"
        label="ครั้งที่พบปัญหา"
        v-model="formData.issueCount">
      </sgv-input-number>

      <sgv-input-text
        :disabled="$auth.disabled(method) || !!formData.qmrApprovedAt"
        class="col-6 col-sm-3"
        label="หมายเลขผลิตภัณฑ์"
        v-model="formData.issueProduct">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method) || !!formData.qmrApprovedAt"
        class="col-6 col-sm-3"
        label="มาตรฐานที่เกี่ยวข้อง"
        v-model="formData.issueStandard">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method) || !!formData.qmrApprovedAt"
        class="col-6 col-sm-3"
        label="เอกสารที่เกี่ยวข้อง"
        v-model="formData.issueDocument">
      </sgv-input-text>
    </div>

    <div class="hr-divider mb-3 my-2">
      <ul class="nav nav-pills hr-divider-content hr-divider-nav">
        <li class="nav-item">
          <a class="nav-link active" href="#" @click.prevent="">
            ส่วนที่ 1 บันทึกสิ่งที่ไม่สอดคล้องกับเอกสารและ/หรือข้อกำหนด
          </a>
        </li>
      </ul>
    </div>

    <div class="form-row">
      <sgv-input-textarea
        :disabled="$auth.disabled(method) || !!formData.qmrApprovedAt"
        :rows="3"
        class="col-12"
        label="สิ่งที่ไม่สอดคล้องและ/หรือ ข้อบกพร่องที่ไม่เป็นไปตามเอกสาร"
        v-model="formData.content"
        :validations="[
          {text: 'required!', value: !v.formData.content.required && v.formData.$dirty}
        ]">
      </sgv-input-textarea>

      <sgv-input-radio
        :noClick="$auth.disabled(method) || !!formData.qmrApprovedAt"
        label="QMR อนุมัติ/ไม่อนุมัติ"
        class="col-12"
        :options="approvedOptions"
        select="value"
        inline
        v-model="formData.isActive"
        :validations="[
          {text: 'required!', value: !v.formData.isActive.required && v.formData.$dirty}
        ]">
        <template slot-scope="option">
          <span :class="option.class">{{option.text}}</span>
        </template>
      </sgv-input-radio>

      <sgv-input-text
        v-show="!formData.isActive"
        :disabled="$auth.disabled(method) || !!formData.qmrApprovedAt"
        class="col-12"
        label="หมายเหตุ"
        v-model="formData.approvedRemark">
      </sgv-input-text>

      <DetailFormUser
        class="col-4"
        title="ผู้ตรวจ (Auditor)"
        :value="getUser('auditorApproved')"
        :isSubmit="formData.reviewedAt && !formData.auditorApprovedAt && $auth.hasRole(`paper:${paperType}:auditorApprove`)"
        :isUndo="!formData.auditeeApprovedAt && formData.auditorApprovedAt && $auth.hasRole(`paper:${paperType}:auditorApprove`)"
        :submitFunc="auditorApproveSubmit"
        :undoFunc="auditorApproveUndo"
        @updated="$emit('updated', null)">
      </DetailFormUser>

      <DetailFormUser
        class="col-4"
        title="ผู้รับผิดชอบ (Auditee)"
        :value="getUser('auditeeApproved')"
        :isSubmit="formData.auditorApprovedAt && !formData.auditeeApprovedAt && $auth.hasRole(`paper:${paperType}:auditeeApprove`)"
        :isUndo="!formData.qmrApprovedAt && formData.auditeeApprovedAt && $auth.hasRole(`paper:${paperType}:auditeeApprove`)"
        :submitFunc="auditeeApproveSubmit"
        :undoFunc="auditeeApproveUndo"
        @updated="$emit('updated', null)">
      </DetailFormUser>

      <DetailFormUser
        class="col-4"
        title="QMR อนุมัติ CPAR"
        :value="getUser('qmrApproved')"
        :isSubmit="formData.auditeeApprovedAt && !formData.qmrApprovedAt && $auth.hasRole(`paper:${paperType}:qmrApprove`)"
        :isUndo="!formData.auditeeRespondedAt && formData.qmrApprovedAt && $auth.hasRole(`paper:${paperType}:qmrApprove`)"
        :submitFunc="qmrApproveSubmit"
        :undoFunc="qmrApproveUndo"
        @updated="$emit('updated', null)">
      </DetailFormUser>
    </div>

    <div class="hr-divider mb-3 my-2">
      <ul class="nav nav-pills hr-divider-content hr-divider-nav">
        <li class="nav-item">
          <a class="nav-link active" href="#" @click.prevent="">
            ส่วนที่ 2 บันทึกการปฎิบัติการแก้ไข/การป้องกัน
          </a>
        </li>
      </ul>
    </div>

    <div class="form-row">
      <sgv-input-textarea
        :disabled="$auth.disabled(method) || !!formData.qmrRespondedAt"
        :rows="3"
        class="col-12"
        label="วิเคราะห์สาเหตุของปัญหา"
        v-model="formData.analyze">
      </sgv-input-textarea>

      <sgv-input-textarea
        :disabled="$auth.disabled(method) || !!formData.qmrRespondedAt"
        :rows="3"
        class="col-12"
        label="การแก้ไข"
        v-model="formData.revisionPlan">
      </sgv-input-textarea>

      <sgv-input-textarea
        :disabled="$auth.disabled(method) || !!formData.qmrRespondedAt"
        :rows="3"
        class="col-12"
        label="การป้องกัน"
        v-model="formData.protectionPlan">
      </sgv-input-textarea>

      <sgv-input-datetime
        isNull
        :disabled="$auth.disabled(method) || !!formData.qmrRespondedAt"
        class="col-4"
        label="กำหนดแล้วเสร็จภายใน"
        v-model="formData.respondedAt">
      </sgv-input-datetime>

      <DetailFormUser
        class="col-4"
        title="ผู้รับผิดชอบแก้ไข"
        :value="getUser('auditeeResponded')"
        :isSubmit="formData.qmrApprovedAt && !formData.auditeeRespondedAt && $auth.hasRole(`paper:${paperType}:auditeeRespond`)"
        :isUndo="!formData.qmrRespondedAt && formData.auditeeRespondedAt && $auth.hasRole(`paper:${paperType}:auditeeRespond`)"
        :submitFunc="auditeeRespondSubmit"
        :undoFunc="auditeeRespondUndo"
        @updated="$emit('updated', null)">
      </DetailFormUser>

      <DetailFormUser
        class="col-4"
        title="QMR อนุมัติแก้ไข"
        :value="getUser('qmrResponded')"
        :isSubmit="formData.auditeeRespondedAt && !formData.qmrRespondedAt && $auth.hasRole(`paper:${paperType}:qmrRespond`)"
        :isUndo="!formData.auditorClosedAt && formData.qmrRespondedAt && $auth.hasRole(`paper:${paperType}:qmrRespond`)"
        :submitFunc="qmrRespondSubmit"
        :undoFunc="qmrRespondUndo"
        @updated="$emit('updated', null)">
      </DetailFormUser>
    </div>

    <div class="hr-divider mb-3 my-2">
      <ul class="nav nav-pills hr-divider-content hr-divider-nav">
        <li class="nav-item">
          <a class="nav-link active" href="#" @click.prevent="">
            ส่วนที่ 3 การตรวจติดตามผล
          </a>
        </li>
      </ul>
    </div>

    <div class="form-row">
      <sgv-input-radio
        :noClick="$auth.disabled(method) || !!formData.approvedAt"
        label="ผลการติดตาม"
        class="col-12"
        :options="closedOptions"
        select="value"
        inline
        v-model="formData.isSatisfied"
        :validations="[
          {text: 'required!', value: !v.formData.isSatisfied.required && v.formData.$dirty}
        ]">
        <template slot-scope="option">
          <span :class="option.class">{{option.text}}</span>
        </template>
      </sgv-input-radio>

      <sgv-input-textarea
        :disabled="$auth.disabled(method) || !!formData.approvedAt"
        :rows="3"
        class="col-12"
        label="หลักฐานอ้างอิงที่ตรวจพบ"
        v-model="formData.correctionResult">
      </sgv-input-textarea>

      <DetailFormUser
        class="col-4"
        title="ผู้ติดตามผล"
        :value="getUser('auditorClosed')"
        :isSubmit="formData.qmrRespondedAt && !formData.auditorClosedAt && $auth.hasRole(`paper:${paperType}:auditorClose`)"
        :isUndo="!formData.approvedAt && formData.auditorClosedAt && $auth.hasRole(`paper:${paperType}:auditorClose`)"
        :submitFunc="auditorCloseSubmit"
        :undoFunc="auditorCloseUndo"
        @updated="$emit('updated', null)">
      </DetailFormUser>

      <DetailFormUser
        class="col-4"
        title="QMR ปิด CPAR"
        :value="getUser('approved')"
        :isSubmit="formData.auditorClosedAt && !formData.approvedAt && $auth.hasRole(`paper:${paperType}:approve`)"
        :isUndo="formData.approvedAt && $auth.hasRole(`paper:${paperType}:approve`)"
        :submitFunc="approveSubmit"
        :undoFunc="approveUndo"
        @updated="$emit('updated', null)">
      </DetailFormUser>
    </div>
  </div>
</template>

<script>
import DetailFormCparInput from './DetailFormCparInput'
import DetailFormUser from './DetailFormUser'
import {
  REVIEW_SUBMIT_PAPER,
  REVIEW_UNDO_PAPER,
  AUDITOR_APPROVE_SUBMIT_PAPER,
  AUDITOR_APPROVE_UNDO_PAPER,
  AUDITEE_APPROVE_SUBMIT_PAPER,
  AUDITEE_APPROVE_UNDO_PAPER,
  QMR_APPROVE_SUBMIT_PAPER,
  QMR_APPROVE_UNDO_PAPER,
  AUDITEE_RESPOND_SUBMIT_PAPER,
  AUDITEE_RESPOND_UNDO_PAPER,
  QMR_RESPOND_SUBMIT_PAPER,
  QMR_RESPOND_UNDO_PAPER,
  AUDITOR_CLOSE_SUBMIT_PAPER,
  AUDITOR_CLOSE_UNDO_PAPER,
  APPROVE_SUBMIT_PAPER,
  APPROVE_UNDO_PAPER,
} from './graph'

export default {
  props: {
    paperId: {
      type: Number,
      required: true
    },
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    v: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      cparTypes: [
        {text: 'ดำเนินการแก้ไข (NC)', value: 'nc', class: ['text-danger']},
        {text: 'ดำเนินการป้องกัน (O)', value: 'o', class: ['text-warning']}
      ],
      cparNames: [
        {text: 'การตรวจติดตามภายใน (IA)', value: 'IA'},
        {text: 'การตรวจติดตามคุณภาพจากหน่วยงานภายนอก (EA)', value: 'EA'},
        {text: 'คำร้องเรียนจากลูกค้า (CP)', value: 'CP'},
        {text: 'ผลิตภัณฑ์/บริการที่ไม่เป็นไปตามข้อกำหนด (NC)', value: 'NC'},
        {text: 'อื่นๆ (OT)', value: 'OT'}
      ],
      approvedOptions: [
        {text: 'อนุมัติคำขอเปิดใน CPAR', value: true, class: ['text-success']},
        {text: 'ไม่อนุมัติคำขอเปิด CPAR', value: false, class: ['text-danger']}
      ],
      closedOptions: [
        {text: 'ผลเป็นที่น่าพอใจ', value: true, class: ['text-success']},
        {text: 'ผลไม่เป็นที่น่าพอใจ', value: false, class: ['text-danger']}
      ],
    }
  },
  methods: {
    getUser (type) {
      const date = this.formData[type + 'At']
      const user = this.formData[type + 'User']
      if (!date || !user) return ''
      return this.$date.format(date).display + ' | ' + user.name
    },
    reviewSubmit (date) {
      return this.$apollo.mutate({
        mutation: REVIEW_SUBMIT_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          submitDate: date
        }
      })
    },
    reviewUndo () {
      return this.$apollo.mutate({
        mutation: REVIEW_UNDO_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
        }
      })
    },
    auditorApproveSubmit (date) {
      return this.$apollo.mutate({
        mutation: AUDITOR_APPROVE_SUBMIT_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          submitDate: date
        }
      })
    },
    auditorApproveUndo () {
      return this.$apollo.mutate({
        mutation: AUDITOR_APPROVE_UNDO_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
        }
      })
    },
    auditeeApproveSubmit (date) {
      return this.$apollo.mutate({
        mutation: AUDITEE_APPROVE_SUBMIT_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          submitDate: date
        }
      })
    },
    auditeeApproveUndo () {
      return this.$apollo.mutate({
        mutation: AUDITEE_APPROVE_UNDO_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
        }
      })
    },
    qmrApproveSubmit (date) {
      return this.$apollo.mutate({
        mutation: QMR_APPROVE_SUBMIT_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          submitDate: date
        }
      })
    },
    qmrApproveUndo () {
      return this.$apollo.mutate({
        mutation: QMR_APPROVE_UNDO_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
        }
      })
    },
    auditeeRespondSubmit (date) {
      return this.$apollo.mutate({
        mutation: AUDITEE_RESPOND_SUBMIT_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          submitDate: date
        }
      })
    },
    auditeeRespondUndo () {
      return this.$apollo.mutate({
        mutation: AUDITEE_RESPOND_UNDO_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
        }
      })
    },
    qmrRespondSubmit (date) {
      return this.$apollo.mutate({
        mutation: QMR_RESPOND_SUBMIT_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          submitDate: date
        }
      })
    },
    qmrRespondUndo () {
      return this.$apollo.mutate({
        mutation: QMR_RESPOND_UNDO_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
        }
      })
    },
    auditorCloseSubmit (date) {
      return this.$apollo.mutate({
        mutation: AUDITOR_CLOSE_SUBMIT_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          submitDate: date
        }
      })
    },
    auditorCloseUndo () {
      return this.$apollo.mutate({
        mutation: AUDITOR_CLOSE_UNDO_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
        }
      })
    },
    approveSubmit (date) {
      return this.$apollo.mutate({
        mutation: APPROVE_SUBMIT_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          submitDate: date
        }
      })
    },
    approveUndo () {
      return this.$apollo.mutate({
        mutation: APPROVE_UNDO_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
        }
      })
    },
  },
  components: {
    DetailFormUser,
    DetailFormCparInput
  }
}
</script>

<style lang="css">
</style>
