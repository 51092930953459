import gql from 'graphql-tag'

const detailResponse = `
  id type
  code name remark
  reviewedAt reviewedUser {id name}
  cparId cparType cparName cparCount cparRemark
  issueCount issueProduct issueStandard issueDocument
  content isActive approvedRemark
  auditorApprovedAt auditorApprovedBy auditorApprovedUser {id name}
  auditeeApprovedAt auditeeApprovedBy auditeeApprovedUser {id name}
  qmrApprovedAt qmrApprovedBy qmrApprovedUser {id name}
  analyze revisionPlan protectionPlan respondedAt
  auditeeRespondedAt auditeeRespondedBy auditeeRespondedUser {id name}
  qmrRespondedAt qmrRespondedBy qmrRespondedUser {id name}
  isSatisfied correctionResult
  auditorClosedAt auditorClosedBy auditorClosedUser {id name}
  approvedAt approvedBy approvedUser {id name}
`

export const LIST_PAPER = (templateType) => gql`query LIST_PAPER ($paperType: String!, $q: FilterInput) {
  papers: list${templateType} (paperType: $paperType, q: $q) {
    id type code name isActive
    approvedBy
  }
}`

export const DETAIL_PAPER = (templateType) => gql`query DETAIL_PAPER ($paperType: String!, $paperId: Int!) {
  paper: detail${templateType} (paperType: $paperType, paperId: $paperId) {${detailResponse}}
}`

export const CREATE_PAPER = (templateType) => gql`mutation CREATE_PAPER ($paperType: String!, $input: ${templateType}Input!) {
  paper: create${templateType} (paperType: $paperType, input: $input) {${detailResponse}}
}`

export const UPDATE_PAPER = (templateType) => gql`mutation UPDATE_PAPER ($paperType: String!, $paperId: Int!, $input: ${templateType}Input!) {
  paper: update${templateType} (paperType: $paperType, paperId: $paperId, input: $input) {${detailResponse}}
}`

export const DESTROY_PAPER = (templateType) => gql`mutation DESTROY_PAPER ($paperType: String!, $paperId: Int!) {
  paper: destroy${templateType} (paperType: $paperType, paperId: $paperId) {id}
}`

export const DROPDOWN_CPAR = (templateType) => gql`query DROPDOWN_CPAR ($paperType: String!, $paperId: Int!, $q: FilterInput) {
  papers: list${templateType}DropdownCpar (paperType: $paperType, paperId: $paperId, q: $q) {
    id code name
  }
}`

export const REVIEW_SUBMIT_PAPER = (templateType) => gql`mutation REVIEW_SUBMIT_PAPER ($paperType: String!, $paperId: Int!, $submitDate: Date!) {
  paper: reviewSubmit${templateType} (paperType: $paperType, paperId: $paperId, submitDate: $submitDate) {${detailResponse}}
}`

export const REVIEW_UNDO_PAPER = (templateType) => gql`mutation REVIEW_UNDO_PAPER ($paperType: String!, $paperId: Int!) {
  paper: reviewUndo${templateType} (paperType: $paperType, paperId: $paperId) {${detailResponse}}
}`

export const AUDITOR_APPROVE_SUBMIT_PAPER = (templateType) => gql`mutation AUDITOR_APPROVE_SUBMIT_PAPER ($paperType: String!, $paperId: Int!, $submitDate: Date!) {
  paper: auditorApproveSubmit${templateType} (paperType: $paperType, paperId: $paperId, submitDate: $submitDate) {${detailResponse}}
}`

export const AUDITOR_APPROVE_UNDO_PAPER = (templateType) => gql`mutation AUDITOR_APPROVE_UNDO_PAPER ($paperType: String!, $paperId: Int!) {
  paper: auditorApproveUndo${templateType} (paperType: $paperType, paperId: $paperId) {${detailResponse}}
}`

export const AUDITEE_APPROVE_SUBMIT_PAPER = (templateType) => gql`mutation AUDITEE_APPROVE_SUBMIT_PAPER ($paperType: String!, $paperId: Int!, $submitDate: Date!) {
  paper: auditeeApproveSubmit${templateType} (paperType: $paperType, paperId: $paperId, submitDate: $submitDate) {${detailResponse}}
}`

export const AUDITEE_APPROVE_UNDO_PAPER = (templateType) => gql`mutation AUDITEE_APPROVE_UNDO_PAPER ($paperType: String!, $paperId: Int!) {
  paper: auditeeApproveUndo${templateType} (paperType: $paperType, paperId: $paperId) {${detailResponse}}
}`

export const QMR_APPROVE_SUBMIT_PAPER = (templateType) => gql`mutation QMR_APPROVE_SUBMIT_PAPER ($paperType: String!, $paperId: Int!, $submitDate: Date!) {
  paper: qmrApproveSubmit${templateType} (paperType: $paperType, paperId: $paperId, submitDate: $submitDate) {${detailResponse}}
}`

export const QMR_APPROVE_UNDO_PAPER = (templateType) => gql`mutation QMR_APPROVE_UNDO_PAPER ($paperType: String!, $paperId: Int!) {
  paper: qmrApproveUndo${templateType} (paperType: $paperType, paperId: $paperId) {${detailResponse}}
}`

export const AUDITEE_RESPOND_SUBMIT_PAPER = (templateType) => gql`mutation AUDITEE_RESPOND_SUBMIT_PAPER ($paperType: String!, $paperId: Int!, $submitDate: Date!) {
  paper: auditeeRespondSubmit${templateType} (paperType: $paperType, paperId: $paperId, submitDate: $submitDate) {${detailResponse}}
}`

export const AUDITEE_RESPOND_UNDO_PAPER = (templateType) => gql`mutation AUDITEE_RESPOND_UNDO_PAPER ($paperType: String!, $paperId: Int!) {
  paper: auditeeRespondUndo${templateType} (paperType: $paperType, paperId: $paperId) {${detailResponse}}
}`

export const QMR_RESPOND_SUBMIT_PAPER = (templateType) => gql`mutation QMR_RESPOND_SUBMIT_PAPER ($paperType: String!, $paperId: Int!, $submitDate: Date!) {
  paper: qmrRespondSubmit${templateType} (paperType: $paperType, paperId: $paperId, submitDate: $submitDate) {${detailResponse}}
}`

export const QMR_RESPOND_UNDO_PAPER = (templateType) => gql`mutation QMR_RESPOND_UNDO_PAPER ($paperType: String!, $paperId: Int!) {
  paper: qmrRespondUndo${templateType} (paperType: $paperType, paperId: $paperId) {${detailResponse}}
}`

export const AUDITOR_CLOSE_SUBMIT_PAPER = (templateType) => gql`mutation AUDITOR_CLOSE_SUBMIT_PAPER ($paperType: String!, $paperId: Int!, $submitDate: Date!) {
  paper: auditorCloseSubmit${templateType} (paperType: $paperType, paperId: $paperId, submitDate: $submitDate) {${detailResponse}}
}`

export const AUDITOR_CLOSE_UNDO_PAPER = (templateType) => gql`mutation AUDITOR_CLOSE_UNDO_PAPER ($paperType: String!, $paperId: Int!) {
  paper: auditorCloseUndo${templateType} (paperType: $paperType, paperId: $paperId) {${detailResponse}}
}`

export const APPROVE_SUBMIT_PAPER = (templateType) => gql`mutation APPROVE_SUBMIT_PAPER ($paperType: String!, $paperId: Int!, $submitDate: Date!) {
  paper: approveSubmit${templateType} (paperType: $paperType, paperId: $paperId, submitDate: $submitDate) {${detailResponse}}
}`

export const APPROVE_UNDO_PAPER = (templateType) => gql`mutation APPROVE_UNDO_PAPER ($paperType: String!, $paperId: Int!) {
  paper: approveUndo${templateType} (paperType: $paperType, paperId: $paperId) {${detailResponse}}
}`
