<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">
          {{title}}
        </h3>
      </div>
    </div>

    <hr class="my-3">

    <div>
      <sgv-form :method.sync="method" :options="options">
        <DetailForm
          :paperId="paperId"
          :paperType="paperType"
          :templateType="templateType"
          :formData="formData"
          :method="method"
          :v="$v"
          @updated="refetch">
        </DetailForm>
      </sgv-form>

      <template v-if="paperId !== 0">
        <HrDivider :options="tabs" v-model="selectedTab"></HrDivider>

        <div v-if="selectedTab === 'DetailPrint'">
          <DetailPrint
            :paperId="paperId"
            :paperType="paperType"
            :templateType="templateType"
            :formData="formData"
            :url="`/paper/${paperType}/${paperId}/print`"
            :filename="`${formData.code}.pdf`">
          </DetailPrint>
        </div>

        <div v-else-if="selectedTab === 'DetailAttachment'">
          <DetailAttachment
            :paperId="paperId"
            :paperType="paperType"
            :templateType="templateType"
            :method="method"
            :canDestroy="!formData.approvedAt">
          </DetailAttachment>
        </div>

        <div v-else-if="selectedTab === 'DetailTrello'">
          <DetailTrello
            :title="trelloTitle"
            :paperId="paperId"
            :paperType="paperType"
            :templateType="templateType">
          </DetailTrello>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {
  DETAIL_PAPER,
  CREATE_PAPER,
  UPDATE_PAPER,
  DESTROY_PAPER
} from './graph'
import DetailForm from './DetailForm'
import DetailPrint from '@/views/paper_template/components/DetailPrint'
import DetailAttachment from '@/views/paper_template/components/Attachment'
import DetailTrello from '@/views/paper_template/components/DetailTrello.vue'

export default {
  props: {
    paperId: {
      type: Number,
      required: true
    },
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      method: 'info',
      selectedTab: 'DetailTrello',
      tabs: [
        {text: 'พิมพ์', value: 'DetailPrint'},
        {text: 'Trello', value: 'DetailTrello'},
        {text: 'ไฟล์แนบ', value: 'DetailAttachment'},
      ],
      listView: `Paper${this.$form.capitalize(this.paperType)}List`,
      isLoaded: false,
      formData: {
        code: '',
        name: '',
        remark: '',
        reviewedAt: null,
        reviewedUser: {},

        cparId: null,
        cparType: 'nc',
        cparName: 'IA',
        cparCount: 1,
        cparRemark: '',

        issueCount: 1,
        issueProduct: '',
        issueStandard: '',
        issueDocument: '',
        content: '',
        isActive: true,
        approvedRemark: '',

        auditorApprovedAt: null,
        auditorApprovedBy: null,
        auditorApprovedUser: {},

        auditeeApprovedAt: null,
        auditeeApprovedBy: null,
        auditeeApprovedUser: {},

        qmrApprovedAt: null,
        qmrApprovedBy: null,
        qmrApprovedUser: {},

        analyze: '',
        revisionPlan: '',
        protectionPlan: '',
        respondedAt: null,

        auditeeRespondedAt: null,
        auditeeRespondedBy: null,
        auditeeRespondedUser: {},

        qmrRespondedAt: null,
        qmrRespondedBy: null,
        qmrRespondedUser: {},

        isSatisfied: false,
        correctionResult: '',

        auditorClosedAt: null,
        auditorClosedBy: null,
        auditorClosedUser: {},

        approvedAt: null,
        approvedBy: null,
        approvedUser: {},
      }
    }
  },
  validations: {
    formData: {
      name: {required},
      cparType: {required},
      cparName: {required},
      content: {required},
      isActive: {required},
      isSatisfied: {required}
    }
  },
  computed: {
    trelloTitle () {
      return `${this.title} (${this.subTitle}): ${this.formData.code}`
    },
    options () {
      return [
        {
          text: 'เพิ่ม',
          variant: 'success',
          method: 'add',
          func: this.createData,
          disabled: true
        },
        {
          text: 'แก้ไข',
          variant: 'warning',
          method: 'edit',
          func: this.updateData,
          disabled: this.formData.approvedAt
        },
        {
          text: 'ลบ',
          variant: 'danger',
          method: 'delete',
          func: this.destroyData,
          disabled: this.formData.reviewedAt || !this.$auth.hasRole(`paper:${this.paperType}:add`)
        },
        {
          text: 'กลับ',
          variant: 'primary',
          func: this.closeForm,
          header: true,
          align: 'right'
        },
      ]
    }
  },
  methods: {
    refetch () {
      this.dataDetail()
    },
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.setFormData(res.data.paper)
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
      .finally(() => {
        this.isLoaded = true
      })
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        if (['reportedAt', 'cparAt', 'respondedAt'].includes(key)) {
          this.formData[key] = this.$date.format(v[key]).datetime
        } else {
          this.formData[key] = v[key]
        }
      })
    },
    serializeInput (v) {
      return  {
        code: v.code,
        name: v.name,
        remark: v.remark,
        cparId: v.cparId,
        cparType: v.cparType,
        cparName: v.cparName,
        cparCount: v.cparCount,
        cparAt: v.cparAt ? this.$date.format(v.cparAt).raw : null,
        cparRemark: v.cparRemark,
        issueCount: v.issueCount,
        issueProduct: v.issueProduct,
        issueStandard: v.issueStandard,
        issueDocument: v.issueDocument,
        content: v.content,
        isActive: v.isActive,
        approvedRemark: v.approvedRemark,
        analyze: v.analyze,
        revisionPlan: v.revisionPlan,
        protectionPlan: v.protectionPlan,
        respondedAt: v.respondedAt ? this.$date.format(v.respondedAt).raw : null,
        isSatisfied: v.isSatisfied,
        correctionResult: v.correctionResult,
      }
    },
    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, paperId: res.data.paper.id},
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: UPDATE_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          input
        }
      })
      .then(res => {
        this.setFormData(res.data.paper)
        this.$emit('updateData', null)
        this.method = 'info'
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(this.$toasted.global.error)
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.listView) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.go(-1)
        }
      } else {
        this.$router.push({name: this.listView})
      }
    }
  },
  created () {
    if (this.paperId === 0) {
      this.method = 'add'
      this.isLoaded = true
    } else {
      this.dataDetail()
    }
  },
  components: {
    DetailForm,
    DetailAttachment,
    DetailPrint,
    DetailTrello
  }
}
</script>

<style lang="css">
</style>
